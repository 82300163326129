/* eslint-disable no-console */
import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import GlobalStyle from '../../assets/globalStyle';
import { lightTheme, darkTheme } from '../../assets/theme/theme';
import useDarkMode from '../../hooks/useDarkMode';
import useLocalStorage from '../../hooks/useLocalStorage';

const App = styled.div`
  margin: 0 auto;
  width: 60%;
  background-color: ${({ theme }) => theme.backgroundColor};
  cursor: default;
  transition: 300ms ease-in-out;
  margin-top: 5%;

  @font-face {
    font-family: CooperHewitt;
    src: url('../assets/webfonts/CooperHewitt-Bold.otf');
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

const StyledVideoIFrame = styled.iframe`
  width: 100%;
  height: 200px;
  border-radius: 10px;

  @media screen and (max-width: 800px) {
    height: 150px;
  }
`;

const VideoLabel = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  border-radius: 5px;
  font-size: 0.8em;
`;

const ChannelDiv = styled.div`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
`;

const ChannelHeader = ({ channel }: any) => {
  if (!channel) {
    return null;
  }

  return (
    <ChannelDiv>
      <p>Total Vlogs: {channel.statistics?.videoCount}</p>
      <p>Views: {channel.statistics?.viewCount}</p>
      <p>Subscribers: {channel.statistics?.subscriberCount}</p>
    </ChannelDiv>
  );
};

const VideoGallery = () => {
  const [theme, componentMounted] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;
  const [videos, setVideos] = useLocalStorage('videos', []);
  const [channel, setChannel] = useLocalStorage('channel', {});

  useEffect(() => {
    const fetchVideos = async () => {
      if (videos.length === 0) {
        console.log('Fetching videos from YouTube API');

        const channelId = process.env.REACT_APP_CHANNEL_ID;
        const apiUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&maxResults=20&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`;

        try {
          const searchResponse = await fetch(apiUrl);
          if (!searchResponse.ok) {
            throw new Error(`HTTP error! status: ${searchResponse.status}`);
          }
          const searchData = await searchResponse.json();
          const videoItems = searchData.items.filter(
            (item: any) => item.id.kind === 'youtube#video'
          );

          const videosWithStatsPromises = videoItems.map(async (video: any) => {
            const videoDetailsResponse = await fetch(
              `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${video.id.videoId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
            );
            const videoDetailsData = await videoDetailsResponse.json();
            return {
              ...video,
              statistics: videoDetailsData.items[0].statistics,
            };
          });

          const videosWithStats = await Promise.all(videosWithStatsPromises);
          setVideos(videosWithStats);
        } catch (error) {
          console.error('Error fetching data: ', error);
        }
      } else {
        console.log('Loading videos from localStorage');
      }
    };

    // eslint-disable-next-line consistent-return
    const fetchChannelDetails = async () => {
      if (!channel.id) {
        const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
        const channelId = process.env.REACT_APP_CHANNEL_ID;
        const apiUrl = `https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails,statistics&id=${channelId}&maxResults=20&key=${apiKey}`;

        try {
          const response = await fetch(apiUrl);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setChannel(data.items[0]);
        } catch (error) {
          console.error('Error fetching channel data: ', error);
        }
      }
    };

    fetchVideos();
    fetchChannelDetails();
  }, [setVideos, setChannel, videos.length, channel.id]);

  videos.sort((a: any, b: any) => {
    const viewCountA = parseInt(a.statistics.viewCount, 10);
    const viewCountB = parseInt(b.statistics.viewCount, 10);
    return viewCountB - viewCountA;
  });

  if (!componentMounted) {
    return <div />;
  }

  return (
    <ThemeProvider theme={themeMode}>
      <App>
        <GlobalStyle />
        <ChannelHeader channel={channel} />
        <GridWrapper>
          {videos.map((video: any) => (
            <div key={video.id.videoId} style={{ position: 'relative' }}>
              <StyledVideoIFrame
                src={`https://www.youtube.com/embed/${video.id.videoId}`}
                frameBorder="0"
                allowFullScreen
                title={video.snippet.title}
              />
              {video.statistics?.viewCount && (
                <VideoLabel>Views: {video.statistics.viewCount}</VideoLabel>
              )}
            </div>
          ))}
        </GridWrapper>
      </App>
    </ThemeProvider>
  );
};

export default VideoGallery;
