import { useEffect, useState } from 'react';

const useDarkMode = () => {
  const [theme, setTheme] = useState<string>('dark');
  const [componentMounted, setComponentMounted] = useState<boolean>(false);

  const setMode = (mode: string) => {
    window.localStorage.setItem('theme', mode);
    setTheme(mode);
  };

  const toggleTheme = () => {
    if (theme === 'light') {
      setMode('dark');
    } else {
      setMode('light');
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');

    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      localTheme
    ) {   
      setTheme(localTheme);
    } else {
      setTheme('dark');
    }
    setComponentMounted(true);
  }, []);

  return [theme, toggleTheme, componentMounted];
};

export default useDarkMode;
