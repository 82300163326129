/* eslint-disable max-len */
export const darkTheme = {
  name: 'Dark Theme',
  backgroundColor: '#403F45',
  color: '#EAEAEA',
  text: '#EAEAEA',
  border: '1px solid #EAEAEA',
  transition: '300ms ease-in-out',

  settings: {
    sidebarBg: '#f8f9fa',
    sidebarColor: '#21252a',
    sidebarHover: '#DEE2E6',
    toggleBg: '#403F45',
    toggleColor: '#F8F9FA',
    transition: '300ms ease-in-out'
  }
};

export const lightTheme = {
  name: 'Light Theme',
  backgroundColor: '#EAEAEA',
  color: '#403F45',
  text: '#403F45',
  border: '1px solid #403F45',
  transition: '300ms ease-in-out',

  settings: {
    sidebarBg: '#21252a',
    sidebarColor: '#f8f9fa',
    sidebarHover: '#868E96',
    toggleBg: '#EAEAEA',
    toggleColor: '#212529',
    transition: '300ms ease-in-out'
  }
};
