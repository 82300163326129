import { createGlobalStyle } from 'styled-components';
import CooperHewitt from './fonts/webfonts/CooperHewitt-Bold.otf';
import CooperHewittLight from './fonts/webfonts/CooperHewitt-Light.otf';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.backgroundColor};
    overflow-x: hidden;
  }
  body::-webkit-scrollbar {
  width: 1em;
  }
  
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  @font-face {
    font-family: CooperHewitt;
    src: url(${CooperHewitt});
  }

  @font-face {
    font-family: CooperHewittLight;
    src: url(${CooperHewittLight});
  }
  
  html {
    &.u-lock-scroll {
    overflow: hidden !important;
    }
  }

  a {
    color: ${({ theme }) => theme.text};
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
    &:hover {
        -webkit-transition: 300ms ease-in-out;
        transition: 300ms ease-in-out;
    }

  h2 {
    font-family: CooperHewitt, sans-serif;
    transition: all 0.25s linear;
  }

  h3 {
    font-family: CooperHewittLight, sans-serif;
    transition: all 0.25s linear;
  }

  }`;

export default GlobalStyle;
