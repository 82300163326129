import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
// import { CodePen } from 'mdx-embed';
import { CopyBlock, dracula } from 'react-code-blocks';

import GlobalStyle from '../../../assets/globalStyle';
import { lightTheme, darkTheme } from '../../../assets/theme/theme';
import useDarkMode from '../../../hooks/useDarkMode';
import {
  cb1,
  cb2,
  cb3,
  cb4,
  cb5,
  cb6,
  cb7,
  cb8,
  cb9,
  cb10,
  cb11,
  cb12,
  cb13,
  cb14,
  cb15,
  cb16,
  cb17,
  cb18,
  cb19,
  cb20,
  cb21,
  cb22,
  cb23,
  cb24,
  cb25,
  cb26,
  cb27,
  cb28,
  cb29,
  cb30,
  cb31,
  cb32,
  cb33,
  cb34
} from './assets/codeblocks';

const App = styled.div`
  margin: 0 auto;
  width: 50%;
  background-color: ${({ theme }) => theme.backgroundColor};
  cursor: default;
  transition: 300ms ease-in-out;

  @font-face {
    font-family: CooperHewitt;
    src: url('../assets/webfonts/CooperHewitt-Bold.otf');
  }

  @media screen and (max-width: 800px) {
    font-size: 80%;
    width: 80%;
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 140px;
  font-size: 16px;
  color: ${({ theme }) => theme.text};

  @media screen and (max-width: 800px) {
    padding-top: 60px;
    font-size: 80%;
    width: 100%;
  }
`;

const StyledCopyblock = styled.div`
  margin-top: 2%;
  margin-bottom: 2%;
`;

const Sample = () => {
  const [theme, componentMounted] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  if (!componentMounted) {
    return <div />;
  }
  return (
    <ThemeProvider theme={themeMode}>
      <App>
        <GlobalStyle />
        <Wrapper>
          <>
            <h1>
              Mern Stack Guide for making an authentication API utilizing JWT
            </h1>
            <p>
              Hello everyone! This is about the shortest, most lightweight guide
              i could come up with on making a fullstack application utilizing
              the MERN stack. Almost every app you build will deal with
              authentication and this guide will give you everything you need to
              handle authentication with the MERN stack. Most courses you take
              include how to handle authentication but are 40 hours long so i
              really wanted to cut out as much abstraction as possible. Feel
              free to clone, fork, modify this app, make it your own. I insist.
              For repo Click Here
            </p>
            <h4> System Requirements</h4>
            <ul>
              <li>Node</li>
              <li>MongoDB Atlas</li>
              <li>express</li>
              <li>express-validator</li>
              <li>bcryptjs</li>
              <li>JWT</li>
              <li>mongoose</li>
              <li>dotenv</li>
            </ul>
            <h2>What is JWT and why is it useful?</h2>
            <p>
              JWT is useful for Authorization and Information exchange. Can be
              sent via URL/ Post request/HTTP Header which makes it fast for
              transmission and usable. It contains the details of user (not
              session id in cookies like traditional request) so , NO need to
              query database to get user details.
            </p>
            <h4>
              Instructions for MongoDB Atlas (this is where you get your
              connection string needed later)
            </h4>
            <ol>
              <li>
                Visit Official Docs , After signing up, click on + New Project
              </li>
              <li>Name your project</li>
              <li>Click Create Project</li>
              <li>Click Build a Database</li>
              <li>Select FREE tier and click Create</li>
              <li>Choose a cloud provider, I chose AWS, but any will do</li>
              <li>Choose a region, any</li>
              <li>
                Scroll to the bottom and click Create Cluster (could take
                several minutes)
              </li>
              <li>
                Create a User, entering in a username and password and then
                clicking Create User
              </li>
              <li>
                Select where you would like to connect from, select local, and
                then click Add My Current Ip Address
              </li>
              <li>Click Finish and Close at the bottom of page</li>
              <li>
                In Database Deployments Click the Connect button next to your
                cluster name
              </li>
              <li>
                Click Connect your application, here is where you can get your
                connection string. :smile:
              </li>
            </ol>
            <ol>
              <h2>Lets get started!</h2>
              <li>Create a new folder call it ‘my-authentication’</li>
              <li>
                <p>Pull up terminal bash and execute this statement below</p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb1}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>Npm init will ask basic info, fill out accordingly</li>
              <li>
                <p>Install all packages mentioned previously</p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb2}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>Now create a file index.js at the root adding this code</p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb3}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>
                  Last step is to test, execute below, should see ‘server will
                  start at PORT 4000’ in terminal
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb4}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <h2>Create Model</h2>
              <li>
                <p>
                  Create .env file at the root of your app, here is where you
                  will store your connection string you recieved earlier doing
                  the mongoDB Atlas section. Inside the .env file add this as
                  well as your connection string
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb5}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>
                  Create a config folder and inside create a db.js, add code
                  below
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb6}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <i>
                  Notice InitiateMongoServer is an async function, We will be
                  using async await to work with promises. The word async before
                  a function means one simple thing: a function always returns a
                  promise. Other values are wrapped in a resolved promise
                  automatically.
                </i>
              </li>
              <li>
                <p>
                  Test by running <i>node index.js</i> in your terminal, expect
                  server to connect
                </p>
              </li>
              <li>
                <p>
                  Create a model folder and inside create a <i>User.js</i>
                  (Capitalized), add code below
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb7}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>Update index.js adding code below</p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb8}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <i>
                  Notice we add body-parser which we will use to parse incoming
                  request bodies.
                </i>
              </li>
              <h2>Make route for user sign up</h2>
              <i>If new to routing click here to learn basics</i>
              <li>
                <p>
                  Create a routes folder and inside create a user.js file. Add
                  code below
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb9}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <i>
                  This will be a post request using express-validator for our
                  signup form validation, bcrypt to hash our users password, and
                  jsonwebtoken to encrypt our payload.
                </i>
              </li>
              <li>
                <p>
                  Import in index.js like below and middleware to handles CORs
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb10}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>
                  Test using postman. If you don’t have postman, click here to
                  install and setup. Expect results below.
                </p>
                {/* //todo postman image */}
              </li>
              <h2>Make route for User login.</h2>
              <li>
                <p>Add code below to user.js</p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb11}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>Test using postman.</p>
              </li>
              <h2>Make route to Get User.</h2>
              <li>
                <p>
                  We get tokens back from both user signup and user login, now
                  lets add route to get a user via token. Add code below.
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb12}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb13}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <i>
                  If you run the server you will get an error from the auth
                  parameter so lets make that function.
                </i>
              </li>
              <li>
                <p>
                  Create a middleware folder and inside create a auth.js adding
                  the code below.
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb14}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <i>This function will be used to verify the users token</i>
              </li>
              <li>
                <p>
                  Test using postman. After signing up a user, try getting that
                  same user passing the token you got from signup to the request
                  header
                </p>
                {/* //todo token image */}
              </li>
              <h2>
                Make Controller file for user routes and clean up routes file
              </h2>
              <i>
                This folder will help us when we add more routes to this API
                keeping our route files shorter and more manageable
              </i>
              <li>
                <p>
                  Create a controllers folder and add user.js, add route logic
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb15}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>
                  Update user.js in routes folder to import controller logic
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb16}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
            </ol>
            <ol>
              <h2>Adding frontend to our node app via React</h2>
              <p>
                We will be using Create React App for initiating our project.
                This Frontend react app will live at the root of your node app.
                Look below for file structure.
              </p>
              <li>
                <p>
                  From your Node app root, run the command below in terminal
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb17}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>
                  We are going to use bootstrap 4 in our project for ease, for
                  more info Click here. In your public folder replace index.html
                  with code below.
                </p>
                {/* //todo need links */}
                <StyledCopyblock>
                  <CopyBlock
                    language="html"
                    text={cb18}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>Create components folder within the src folder</p>
              </li>
              <li>
                <p>
                  Create Header folder inside components, inside create a
                  Header.js
                </p>
              </li>
              <li>
                <p>Using bootstrap’s navbar, Add code below to Header.js</p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb19}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>
                  Import Header into App.js replacing its contents with the code
                  below
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="js"
                    text={cb20}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <p>
                  Now go look in browser, expect to see header.
                  <i>
                    We will be using react’s useState hook, Assuming you have
                    basic knowledge of react hooks, for more info Click here.
                  </i>
                </p>
                {/* //todo need links */}
              </li>

              <li>
                <p>Create SignUp folder, inside create SignUp.js</p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb21}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>
                  Run command below in terminal inside ‘frontEnd’ folder/dir.
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb22}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>
                  Create constants folder at frontEnd src dir, Add
                  apiConstants.js file adding code below
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb23}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>Add functions and imports below to SignUp.js</p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb24}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>
                  Setup Client side routing by installing react-router-dom and
                  add routing
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb25}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb26}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>
                  Make Alert folder in components folder, adding Alert.js and
                  Alert.css
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb27}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <p>
                  Notice we pass props as an argument, this will come from the
                  parent Component using this Alert component and we also use
                  Reacts useEffect hook which will wait for props to change from
                  parent
                </p>
              </li>
              <li>
                <p>Add code below to Alert.css</p>
                <StyledCopyblock>
                  <CopyBlock
                    language="css"
                    text={cb28}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <p>
                  Now that we have a sign up page, we need to be able to show a
                  new user their home page after logging in. To protect that
                  users info we can use session tokens.
                </p>
              </li>
              <li>
                <p>
                  Update sendDetailsToServer function in SignUp.js and add
                  import from constants
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb29}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <p>
                  We use localStorage.setItem to store the token received from
                  backend API to browser’s local storage.
                </p>
              </li>
              <li>
                <p>
                  Create utils folder at root of src, name it PrivateRoute.js,
                  add code
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb30}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <p>
                  Here we make a generic Route checking for our token which we
                  will use later for our Home route.
                </p>
              </li>
              <li>
                <p>Create Login component folder, then Login.js file.</p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb31}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
              </li>
              <li>
                <p>Create Home component folder, then Home.js file.</p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb32}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <p>
                  Notice our useEffect function checks to make sure our token is
                  NOT expired by sending it in the headers to our API.
                </p>
              </li>
              <li>
                <p>
                  Now lets update our App.js file to include our home and login
                  component routes.
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb33}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <p>
                  Notice our Home component is wrapped in our PrivateRoute
                  requiring our user has a validated token.
                </p>
              </li>

              <li>
                <p>
                  Update Header component to include dynamic title, and Logout
                  button.
                </p>
                <StyledCopyblock>
                  <CopyBlock
                    language="jsx"
                    text={cb34}
                    showLineNumbers
                    theme={dracula}
                    wrapLines
                    codeBlock
                  />
                </StyledCopyblock>
                <p>
                  The title is determined by which page, and the logout button
                  only shows if you are on the home page.
                </p>
              </li>
            </ol>
            <h2>
              Congrats, you made it through. This wraps up this guide. Now you
              know how to make a production ready authentication API utilizing
              the MERN stack, and what you do from this point is your choice.
              The sky’s is the limit. Dont forget to give my repo a star, and
              thanks for reading.
            </h2>
            {/* <CodePen codePenId="RwYbMoM" /> */}
            {/* <CopyBlock
              language={'jsx'}
              text={cb1}
              showLineNumbers={true}
              theme={dracula}
              wrapLines={true}
              codeBlock
            /> */}
          </>
        </Wrapper>
      </App>
    </ThemeProvider>
  );
};

export default Sample;

// # Mern Stack Guide for making a production ready authentication API utilizing Json Web Tokens

// # Last year’s snowfall

// In {year}, the snowfall was above average.
// It was followed by a warm spring which caused
// flood conditions in many of the nearby rivers.

// Hello everyone! This is about the shortest, most lightweight guide i could come up with on making a fullstack application utilizing the MERN stack. What is MERN? Why use MERN?

// Almost every app you build will deal with authentication and this guide will give you everything you need to handle authentication with the MERN stack. Most courses you take include how to handle authentication but are 40 hours long so i really wanted to cut out as much abstraction as possible. Feel free to clone, fork, modify this app, make it your own. I insist. For repo Click Here

// > A block quote with ~strikethrough~ and a URL: https://reactjs.org.

// ![Portfolio picture](./latestPortPic.png)

// <img src={'./latestPortPic.png'} alt="Image alt" />

// <CodePen codePenId="PNaGbb" />

// <Chart year={year} color="#fcb32c" />

// - Lists
// - [ ] todo
// - [x] done

// A table:

// | a   | b   |
// | --- | --- |

// 👉 Changes are re-rendered as you type.

// 👈 Try writing some markdown on the left.

// # GFM

// ## Autolink literals

// www.example.com, https://example.com, and contact@example.com.

// ## Footnote

// A note[^1]

// [^1]: Big note.

// ## Strikethrough

// ~one~ or ~~two~~ tildes.

// ## Table

// | a   | b   |   c |  d  |
// | --- | :-- | --: | :-: |

// ```js
// const func = () => console.log('Test Blog');
// ```

// ## Tasklist

// - [ ] to do
// - [x] done`
