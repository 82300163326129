import React from 'react';
import styled from 'styled-components';
import darkLogo from '../images/newLogo.svg';
import newLogo from '../images/darkLogo.svg';

const ToggleContainer = styled.button`
  background: ${({ theme }) => theme.settings.toggleBg};
  border: none;
  outline: none;
  text-transform: none;

  &:focus {
    color: ${({ theme }) => theme.settings.toggleColor};
  }
`;

const Logo = styled.img`
  cursor: pointer;
  min-width: 400px;
  min-height: 500px;
  @media screen and (max-width: 800px) {
    width: 80%;
    min-width: 280px;
    min-height: 360px;
  }
`;

// interface toggleProps {
//   theme: string;
//   toggleTheme: () => void;
// }

const Toggle = ({ theme, toggleTheme }: any) => {
  const isLight = theme === 'light';

  return (
    <ToggleContainer onClick={toggleTheme} type="button">
      {isLight ? (
        <>
          <Logo src={darkLogo} loading="lazy" alt="logo" />
        </>
      ) : (
        <>
          <Logo src={newLogo} loading="lazy" alt="logo" />
        </>
      )}
    </ToggleContainer>
  );
};

export default Toggle;
