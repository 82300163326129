import { useState, useEffect } from 'react';

const useLocalStorage = (key, defaultValue, expiryInHours = 24) => {
  // Function to retrieve data from localStorage
  const get = () => {
    const storedData = localStorage.getItem(key);
    if (!storedData) {
      return { value: defaultValue, isExpired: true };
    }

    const { value, timestamp } = JSON.parse(storedData);
    const now = new Date();

    // Check if the data has expired
    const isExpired =
      now.getTime() - timestamp > expiryInHours * 60 * 60 * 1000;
    if (isExpired) {
      localStorage.removeItem(key);
      return { value: defaultValue, isExpired };
    }

    return { value, isExpired };
  };

  // Retrieve and destructure the value and expiration status
  const { value: initialValue, isExpired } = get();

  // State to manage the value
  const [value, setValue] = useState(initialValue);

  // Update localStorage when the value changes
  useEffect(() => {
    const now = new Date();
    const item = {
      value,
      timestamp: now.getTime()
    };
    localStorage.setItem(key, JSON.stringify(item));
  }, [key, value]);

  return [value, setValue, isExpired];
};

export default useLocalStorage;
