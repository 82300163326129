import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: inline;
  left: 0px;
  top: 0px;
  box-shadow: inset -1px 0px 0px #dae1f5;
`;

const ComponentList = styled.ul`
  display: flex;
  padding: 0;
  width: 100vw;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const NavItem = styled(NavLink)`
  text-decoration: none;
  display: inline;
  align-items: center;
  text-align: left;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 500;
  margin-left: 15px;
  margin-right: 15px;

  &:hover,
  &.active {
    background: ${({ theme }) => theme.hover};
    border: ${({ theme }) => theme.border};
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  a {
    text-decoration: none;
  }

  @font-face {
    font-family: CooperHewitt;
    src: url('./CooperHewitt-Bold.otf') format('opentype');
  }

  @media (max-width: 450px) {
    margin: 8px 0;
    font-size: 16px;
  }
`;

export default function Navbar() {
  return (
    <Container>
      <ComponentList>
        <NavItem to="/" exact>
          Home
        </NavItem>
        <NavItem to="/About">About</NavItem>
        <NavItem to="/Videos">Vlog</NavItem>
        <NavItem to="/Repos">Repos</NavItem>
      </ComponentList>
    </Container>
  );
}
