export const cb1 = `npm init`;
export const cb2 = `npm install express express-validator bcryptjs jsonwebtoken mongoose dotenv --save`;
export const cb3 = `const express = require("express")

const app = express()

// PORT
const PORT = process.env.PORT || 4000

app.get("/", (req, res) => {
  res.json({ message: "API Working" })
})

app.listen(PORT, (req, res) => {
  console.log('Server Started')
})`;
export const cb4 = `node index.js`;
export const cb5 = `DB_URI=yourSecretConnectionStringGoesHere`;
export const cb6 = `const mongoose = require("mongoose")
require("dotenv").config()

// Replace this with your MONGOURI.
const MONGOURI = process.env.DB_URI

const InitiateMongoServer = async () => {
  try {
    await mongoose.connect(MONGOURI, {
      useNewUrlParser: true,
    })
    console.log("Connected to DB !!")
  } catch (e) {
    console.log(e)
    throw e
  }
}

module.exports = InitiateMongoServer`;
export const cb7 = `const mongoose = require("mongoose")

const UserSchema = mongoose.Schema({
  username: {
    type: String,
    required: true,
  },
  email: {
    type: String,
    required: true,
  },
  password: {
    type: String,
    required: true,
  },
  createdAt: {
    type: Date,
    default: Date.now(),
  },
})

// export model user with UserSchema
module.exports = mongoose.model("user", UserSchema)`;
export const cb8 = `const express = require("express")
const bodyParser = require("body-parser")
const InitiateMongoServer = require("./config/db")

// Initiate Mongo Server
InitiateMongoServer()

const app = express()

// PORT
const PORT = process.env.PORT || 4000

// Middleware
app.use(bodyParser.json())

app.get("/", (req, res) => {
  res.json({ message: "API Working" })
})

app.listen(PORT, (req, res) => {
  console.log('Server Started')
})`;
export const cb9 = `const express = require("express")
const { check, validationResult } = require("express-validator")
const bcrypt = require("bcryptjs")
const jwt = require("jsonwebtoken")
const router = express.Router()

const User = require("../model/User")

/**
 * @method - POST
 * @param - /signup
 * @description - User SignUp
 */

router.post(
  "/signup",
  [
    check("username", "Please Enter a Valid Username")
      .not()
      .isEmpty(),
    check("email", "Please enter a valid email").isEmail(),
    check("password", "Please enter a valid password").isLength({
      min: 6,
    }),
  ],
  async (req, res) => {
    const errors = validationResult(req)
    if (!errors.isEmpty()) {
      return res.status(400).json({
        errors: errors.array(),
      })
    }

    const { username, email, password } = req.body
    try {
      let user = await User.findOne({
        email,
      })
      if (user) {
        return res.status(400).json({
          msg: "User Already Exists",
        })
      }

      user = new User({
        username,
        email,
        password,
      })

      const salt = await bcrypt.genSalt(10)
      user.password = await bcrypt.hash(password, salt)

      await user.save()

      const payload = {
        user: {
          id: user.id,
        },
      }

      jwt.sign(
        payload,
        "randomString",
        {
          expiresIn: 10000,
        },
        (err, token) => {
          if (err) throw err
          res.status(200).json({
            token,
          })
        }
      )
    } catch (err) {
      console.log(err.message)
      res.status(500).send("Error in Saving")
    }
  }
)

module.exports = router`;
export const cb10 = `const express = require("express")
const bodyParser = require("body-parser")
const user = require("./routes/user") //new addition
const InitiateMongoServer = require("./config/db")

// Initiate Mongo Server
InitiateMongoServer()

const app = express()

// PORT
const PORT = process.env.PORT || 4000

// Middleware
app.use(bodyParser.json())
app.use(express.urlencoded({ extended: true }))
app.use(express.json())
app.use(function(req, res, next) {
  res.header("Access-Control-Allow-Origin", "http://localhost:3000") // update to match the domain you will make the request from
  res.header(
    "Access-Control-Allow-Headers",
    "token, Origin, X-Requested-With, Content-Type, Accept"
  )
  next()
})

app.get("/", (req, res) => {
  res.json({ message: "API Working" })
})

/**
 * Router Middleware
 * Router - /user/*
 * Method - *
 */
app.use("/user", user)

app.listen(PORT, (req, res) => {
  console.log('Server Started')
})`;
export const cb11 = `
  ],
  async (req, res) => {
    const errors = validationResult(req)

    if (!errors.isEmpty()) {
      return res.status(400).json({
        errors: errors.array(),
      })
    }

    const { email, password } = req.body
    try {
      let user = await User.findOne({
        email,
      })
      if (!user)
        return res.status(400).json({
          message: "User Not Exist",
        })

      const isMatch = await bcrypt.compare(password, user.password)
      if (!isMatch)
        return res.status(400).json({
          message: "Incorrect Password !",
        })

      const payload = {
        user: {
          id: user.id,
        },
      }

      jwt.sign(
        payload,
        "randomString",
        {
          expiresIn: 3600,
        },
        (err, token) => {
          if (err) throw err
          res.status(200).json({
            token,
          })
        }
      )
    } catch (e) {
      console.error(e)
      res.status(500).json({
        message: "Server Error",
      })
    }
  }
)`;
export const cb12 = `const auth = require("../middleware/auth")`;
export const cb13 = `router.get("/me", auth, async (req, res) => {
  try {
    // request.user is getting fetched from Middleware after token authentication
    const user = await User.findById(req.user.id)
    res.json(user)
  } catch (e) {
    res.send({ message: "Error in Fetching user" })
  }
})`;
export const cb14 = `const jwt = require("jsonwebtoken")

module.exports = function(req, res, next) {
  const token = req.header("token")
  if (!token) return res.status(401).json({ message: "Auth Error" })

  try {
    const decoded = jwt.verify(token, "randomString")
    req.user = decoded.user
    next()
  } catch (e) {
    console.error(e)
    res.status(500).send({ message: "Invalid Token" })
  }
}`;
export const cb15 = `const { validationResult } = require("express-validator")
const bcrypt = require("bcryptjs")
const jwt = require("jsonwebtoken")

const User = require("../model/User")

exports.signUp = async (req, res) => {
  const errors = validationResult(req)
  if (!errors.isEmpty()) {
    return res.status(400).json({
      errors: errors.array(),
    })
  }

  const { username, email, password } = req.body
  try {
    let user = await User.findOne({
      email,
    })
    if (user) {
      return res.status(400).json({
        msg: "User Already Exists",
      })
    }

    user = new User({
      username,
      email,
      password,
    })

    const salt = await bcrypt.genSalt(10)
    user.password = await bcrypt.hash(password, salt)

    await user.save()

    const payload = {
      user: {
        id: user.id,
      },
    }

    jwt.sign(
      payload,
      "randomString",
      {
        expiresIn: 10000,
      },
      (err, token) => {
        if (err) throw err
        res.status(200).json({
          token,
        })
      }
    )
  } catch (err) {
    console.log(err.message)
    res.status(500).send("Error in Saving")
  }
}

exports.loginUser = async (req, res) => {
  const errors = validationResult(req)

  if (!errors.isEmpty()) {
    return res.status(400).json({
      errors: errors.array(),
    })
  }

  const { email, password } = req.body
  try {
    let user = await User.findOne({
      email,
    })
    if (!user)
      return res.status(400).json({
        message: "User Not Exist",
      })

    const isMatch = await bcrypt.compare(password, user.password)
    if (!isMatch)
      return res.status(400).json({
        message: "Incorrect Password !",
      })

    const payload = {
      user: {
        id: user.id,
      },
    }

    jwt.sign(
      payload,
      "randomString",
      {
        expiresIn: 3600,
      },
      (err, token) => {
        if (err) throw err
        res.status(200).json({
          token,
        })
      }
    )
  } catch (e) {
    console.error(e)
    res.status(500).json({
      message: "Server Error",
    })
  }
}

exports.getMe = async (req, res) => {
  try {
    // request.user is getting fetched from Middleware after token authentication
    const user = await User.findById(req.user.id)
    res.json(user)
  } catch (e) {
    res.send({ message: "Error in Fetching user" })
  }
}`;
export const cb16 = `user = await User.findById(req.user.id)
    res.json(user)
  } catch (e) {
    res.send({ message: "Error in Fetching user" })
  }
}
Update user.js in routes folder to import controller logic
const express = require("express")
const { check } = require("express-validator")

const auth = require("../middleware/auth")
const { signUp, loginUser, getMe } = require("../controllers/user")

const router = express.Router()

router.post(
  "/signup",
  [
    check("username", "Please Enter a Valid Username")
      .not()
      .isEmpty(),
    check("email", "Please enter a valid email").isEmail(),
    check("password", "Please enter a valid password").isLength({
      min: 6,
    }),
  ],
  signUp
)

router.post(
  "/login",
  [
    check("email", "Please enter a valid email").isEmail(),
    check("password", "Please enter a valid password").isLength({
      min: 6,
    }),
  ],
  loginUser
)

router.get("/me", auth, getMe)

module.exports = router`;
export const cb17 = `npx create-react-app frontend
cd frontEnd
npm start`;
export const cb18 = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Web site created using create-react-app"
    />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
      integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
      crossorigin="anonymous"
    />
    <title>React App</title>
  </head>
  <body>
    <div id="root"></div>
    <script
      src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
      integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
      crossorigin="anonymous"
    ></script>
    <script
      src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
      integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
      crossorigin="anonymous"
    ></script>
    <script
      src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
      integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
      crossorigin="anonymous"
    ></script>
  </body>
</html>`;
export const cb19 = `import React from "react"
function Header() {
  return (
    <nav className="navbar navbar-dark bg-primary">
      <div className="row col-12 d-flex justify-content-center text-white">
        <span className="h3">Sign Up</span>
      </div>
    </nav>
  )
}
export default Header`;
export const cb20 = `import React from "react"
import Header from "./components/Header/Header"

function App() {
  return (
    <div className="App">
      <Header />
    </div>
  )
}

export default App`;
export const cb21 = `import React, { useState } from "react"

export default function SignUp(props) {
  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
  })
  const handleChange = e => {
    const { id, value } = e.target
    setUser(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }
  const handleSubmitClick = e => {
    e.preventDefault()
    if (user.password === user.confirmPassword) {
      sendDetailsToServer()
    } else {
      props.showError("Passwords do not match")
    }
  }
  return (
    <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
      <form>
        <div className="form-group text-left">
          <label htmlFor="exampleInputUsername">Username</label>
          <input
            type="text"
            className="form-control"
            id="username"
            aria-describedby="usernameHelp"
            placeholder="Enter username"
            value={user.username}
            onChange={handleChange}
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            value={user.email}
            onChange={handleChange}
          />
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Password"
            value={user.password}
            onChange={handleChange}
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputPassword1">Confirm Password</label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            placeholder="Password"
            value={user.confirmPassword}
            onChange={handleChange}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmitClick}
        >
          Sign Up
        </button>
      </form>
    </div>
  )
}`;
export const cb22 = `npm i --save axios`;
export const cb23 = `export const API_BASE_URL = "http://localhost:4000/user/"
export const ACCESS_TOKEN_NAME = "login_access_token"`;
export const cb24 = `import React, { useState } from "react"
import axios from "axios"
import { API_BASE_URL } from "../../constants/apiConstants"

export default function SignUp(props) {
  // ...
  const sendDetailsToServer = () => {
    if (user.email.length && user.password.length) {
      props.showError(null)
      const payload = {
        username: user.username,
        email: user.email,
        password: user.password,
      }
      axios
        .post(API_BASE_URL + "signup", payload)
        .then(function(response) {
          if (response.status === 200) {
            setUser(prevState => ({
              ...prevState,
              successMessage:
                "Registration successful. Redirecting to home page..",
            }))
            redirectToHome()
            props.showError(null)
          } else {
            props.showError("Some error ocurred")
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    } else {
      props.showError("Please enter valid username and password")
    }
  }
  const redirectToHome = () => {
    props.updateTitle("Home")
    props.history.push("/home")
  }
  // ...
}`;
export const cb25 = `npm i react-router-dom@5.2.0`;
export const cb26 = `import React, { useState } from "react"
import "./App.css"
import Header from "./components/Header/Header"
import SignUp from "./components/SignUp/SignUp"
import Alert from "./components/Alert/Alert"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

function App() {
  const [title, updateTitle] = useState(null)
  const [errorMessage, updateErrorMessage] = useState(null)
  return (
    <Router>
      <div className="App">
        <Header title={title} />
        <div className="container d-flex align-items-center flex-column">
          <Switch>
            <Route path="/" exact={true}>
              <SignUp
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>
          </Switch>
          <Alert errorMessage={errorMessage} hideError={updateErrorMessage} />
        </div>
      </div>
    </Router>
  )
}

export default App`;
export const cb27 = `import React, { useState, useEffect } from "react"
import "./Alert.css"

function Alert(props) {
  const [modalDisplay, toggleDisplay] = useState("none")

  const openModal = () => {
    toggleDisplay("block")
  }
  const closeModal = () => {
    toggleDisplay("none")
    props.hideError(null)
  }
  useEffect(() => {
    if (props.errorMessage !== null) {
      openModal()
    } else {
      closeModal()
    }
  })

  return (
    <div
      className={"alert alert-danger alert-dismissable mt-4"}
      role="alert"
      id="alertPopUp"
      style={{ display: modalDisplay }}
    >
      <div className="d-flex alertMessage">
        <span>{props.errorMessage}</span>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => closeModal()}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  )
}

export default Alert`;
export const cb28 = `/* ALert.css */
.alertMessage {
  min-width: 200px;
  justify-content: space-between;
}`;
export const cb29 = `...
import { API_BASE_URL, ACCESS_TOKEN_NAME } from "../../constants/apiConstants";

...

  const sendDetailsToServer = () => {
    if (user.email.length && user.password.length) {
      props.showError(null);
      const payload = {
        username: user.username,
        email: user.email,
        password: user.password,
      };
      axios
        .post(API_BASE_URL + "signup", payload)
        .then(function (response) {
          if (response.status === 200) {
            setUser((prevState) => ({
              ...prevState,
              successMessage:
                "Registration successful. Redirecting to home page..",
            }));
            localStorage.setItem(ACCESS_TOKEN_NAME, response.data.token);
            redirectToHome();
            props.showError(null);
          } else {
            props.showError("Some error ocurred");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      props.showError("Please enter valid username and password");
    }
  };`;
export const cb30 = `import React from "react"
import { Redirect, Route } from "react-router-dom"
import { ACCESS_TOKEN_NAME } from "../constants/apiConstants"

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem(ACCESS_TOKEN_NAME) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute`;
export const cb31 = `import React, { useState } from "react"
import axios from "axios"
import "./Login.css"
import { API_BASE_URL, ACCESS_TOKEN_NAME } from "../../constants/apiConstants"
import { withRouter } from "react-router-dom"

function Login(props) {
  const [state, setState] = useState({
    email: "",
    password: "",
    successMessage: null,
  })
  const handleChange = e => {
    const { id, value } = e.target
    setState(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }

  const handleSubmitClick = e => {
    e.preventDefault()
    const payload = {
      email: state.email,
      password: state.password,
    }
    axios
      .post(API_BASE_URL + "login", payload)
      .then(function(response) {
        if (response.status === 200) {
          setState(prevState => ({
            ...prevState,
            successMessage: "Login successful. Redirecting to home page..",
          }))
          localStorage.setItem(ACCESS_TOKEN_NAME, response.data.token)
          redirectToHome()
          props.showError(null)
        } else if (response.status === 204) {
          props.showError("Username and password do not match")
        } else {
          props.showError("Username does not exists")
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }
  const redirectToHome = () => {
    props.updateTitle("Home")
    props.history.push("/home")
  }
  const redirectToSignUp = () => {
    props.history.push("/signup")
    props.updateTitle("Sign Up")
  }
  return (
    <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
      <form>
        <div className="form-group text-left">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            value={state.email}
            onChange={handleChange}
          />
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        </div>
        <div className="form-group text-left">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Password"
            value={state.password}
            onChange={handleChange}
          />
        </div>
        <div className="form-check"></div>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmitClick}
        >
          Submit
        </button>
      </form>
      <div
        className="alert alert-success mt-2"
        style={{ display: state.successMessage ? "block" : "none" }}
        role="alert"
      >
        {state.successMessage}
      </div>
      <div className="registerMessage">
        <span>Dont have an account? </span>
        <span className="loginText" onClick={() => redirectToSignUp()}>
          Sign Up
        </span>
      </div>
    </div>
  )
}

export default withRouter(Login)`;
export const cb32 = `import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import axios from "axios"

import { ACCESS_TOKEN_NAME, API_BASE_URL } from "../../constants/apiConstants"

function Home(props) {
  useEffect(() => {
    axios
      .get(API_BASE_URL + "me", {
        headers: { token: localStorage.getItem(ACCESS_TOKEN_NAME) },
      })
      .then(function(response) {
        if (response.status !== 200) {
          redirectToLogin()
        }
      })
      .catch(function(error) {
        redirectToLogin()
      })
  })
  function redirectToLogin() {
    props.history.push("/login")
  }
  return <div className="mt-2">Home page only user should see.</div>
}

export default withRouter(Home)`;
export const cb33 = `import React, { useState } from "react"
import "./App.css"

import PrivateRoute from "./utils/PrivateRoute"
import Header from "./components/Header/Header"
import Login from "./components/Login/Login"
import SignUp from "./components/SignUp/SignUp"
import Home from "./components/Home/Home"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Alert from "./components/Alert/Alert"

function App() {
  const [title, updateTitle] = useState(null)
  const [errorMessage, updateErrorMessage] = useState(null)
  return (
    <Router>
      <div className="App">
        <Header title={title} />
        <div className="container d-flex align-items-center flex-column">
          <Switch>
            <Route path="/" exact={true}>
              <SignUp
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>
            <Route path="/signup">
              <SignUp
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>
            <Route path="/login">
              <Login showError={updateErrorMessage} updateTitle={updateTitle} />
            </Route>
            <PrivateRoute path="/home">
              <Home />
            </PrivateRoute>
          </Switch>
          <Alert errorMessage={errorMessage} hideError={updateErrorMessage} />
        </div>
      </div>
    </Router>
  )
}

export default App`;
export const cb34 = `import React from "react"
import { withRouter } from "react-router-dom"

import { ACCESS_TOKEN_NAME } from "../../constants/apiConstants"

function Header(props) {
  const capitalize = s => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  let title = capitalize(
    props.location.pathname.substring(1, props.location.pathname.length)
  )
  if (props.location.pathname === "/") {
    title = "Welcome"
  }
  function renderLogout() {
    if (props.location.pathname === "/home") {
      return (
        <div className="ml-auto">
          <button className="btn btn-danger" onClick={() => handleLogout()}>
            Logout
          </button>
        </div>
      )
    }
  }
  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME)
    props.history.push("/login")
  }
  return (
    <nav className="navbar navbar-dark bg-primary">
      <div className="row col-12 d-flex justify-content-center text-white">
        <span className="h3">{props.title || title}</span>
        {renderLogout()}
      </div>
    </nav>
  )
}
export default withRouter(Header)`;
