/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, keyframes } from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { GrabberIcon } from '@primer/octicons-react';

import GlobalStyle from '../../assets/globalStyle';
import { lightTheme, darkTheme } from '../../assets/theme/theme';
import useDarkMode from '../../hooks/useDarkMode';
import { ReactComponent as RepoIcon } from '../../assets/images/repo.svg';

const shimmerAnimation = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const App = styled.div`
  margin: 0 auto;
  width: 60%;
  background-color: ${({ theme }) => theme.backgroundColor};
  cursor: default;
  transition: 300ms ease-in-out;
  margin-top: 5%;

  @font-face {
    font-family: CooperHewitt;
    src: url('../assets/webfonts/CooperHewitt-Bold.otf');
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

const RepoBox = styled.div`
  max-width: 500px;
  border: 1px solid ${({ theme }) => theme.text};
  border-radius: 0.375rem;
  padding: 0 10px;
  margin-bottom: 20px;
  overflow: hidden;
  color: ${({ theme }) => theme.text};
`;

const StyledRepoIcon = styled(RepoIcon)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  fill: ${({ theme }) => theme.text};
`;

const LanguageDot = styled.span`
  height: 10px;
  width: 10px;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

const RepoLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const RepoSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
  border: 1px solid ${({ theme }) => theme.text};
  border-radius: 0.375rem;
  padding: 10px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.text};
  min-height: 170px;
`;

const SkeletonLine = styled.div`
  height: 10px;
  background-color: ${({ theme }) => theme.text};
  opacity: 0.3;
  margin: 8px 0;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.backgroundColor} 0%,
    ${({ theme }) => theme.text} 50%,
    ${({ theme }) => theme.backgroundColor} 100%
  );
  background-size: 1000px 104px;
  animation: ${shimmerAnimation} 2s linear infinite;
`;

const SkeletonHeader = styled(Header)`
  justify-content: start;
`;

const SkeletonRepoIcon = styled(StyledRepoIcon)`
  opacity: 0.3;
`;

const StyledGrabberIcon = styled(GrabberIcon)`
  /* Add styles here, e.g., cursor: grab; */
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RepoSkeletonComponent = () => (
  <RepoSkeleton>
    <SkeletonHeader>
      <SkeletonRepoIcon />
      <SkeletonLine style={{ width: '50%', height: '1.5em' }} />
    </SkeletonHeader>
    <SkeletonLine style={{ width: '80%' }} />
    <SkeletonLine style={{ width: '60%' }} />
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <SkeletonLine
        style={{ width: '10px', height: '10px', borderRadius: '50%' }}
      />
      <SkeletonLine style={{ width: '30%', marginLeft: '5px' }} />
    </div>
  </RepoSkeleton>
);

const getLanguageColor = language => {
  switch (language) {
    case 'JavaScript':
      return '#F1E05A';
    case 'HTML':
      return '#E34C26';
    case 'Python':
      return '#3572A5';
    default:
      return language ? 'purple' : 'none';
  }
};

let uniqueIdCounter = 0;
const generateUniqueId = () => {
  const id = `unique-id-${uniqueIdCounter}`;
  uniqueIdCounter += 1;
  return id;
};

const Repos = () => {
  const [theme, componentMounted] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;
  const [repos, setRepos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.github.com/users/gregdbanks/repos?per_page=100`)
      .then(response => response.json())
      .then(data => {
        const sortedRepos = data.sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );
        setRepos(sortedRepos.slice(0, 6));
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        throw new Error(error);
      });
  }, []);

  console.log(repos);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(repos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRepos(items);
  };

  if (loading) {
    return (
      <ThemeProvider theme={themeMode}>
        <App>
          <GlobalStyle />
          <GridWrapper>
            {[...Array(6)].map(() => (
              <RepoSkeletonComponent key={generateUniqueId()} />
            ))}
          </GridWrapper>
        </App>
      </ThemeProvider>
    );
  }

  if (!componentMounted) {
    return <div />;
  }

  return (
    <ThemeProvider theme={themeMode}>
      <App>
        <GlobalStyle />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="repos">
            {provided => (
              <GridWrapper {...provided.droppableProps} ref={provided.innerRef}>
                {repos.map((repo, index) => (
                  <Draggable
                    key={repo.id}
                    draggableId={repo.id.toString()}
                    index={index}
                  >
                    {draggable => (
                      <RepoBox
                        ref={draggable.innerRef}
                        {...draggable.draggableProps}
                        {...draggable.dragHandleProps}
                      >
                        <Header>
                          <StyledSection>
                            <StyledRepoIcon />
                            <h2>
                              <RepoLink
                                href={repo.html_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {repo.name}
                              </RepoLink>
                            </h2>
                          </StyledSection>

                          <StyledGrabberIcon />
                        </Header>
                        <p>{repo.description}</p>
                        <p>
                          {repo.language && (
                            <LanguageDot
                              color={getLanguageColor(repo.language)}
                            />
                          )}
                          {repo.language}
                        </p>
                      </RepoBox>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </GridWrapper>
            )}
          </Droppable>
        </DragDropContext>
      </App>
    </ThemeProvider>
  );
};

export default Repos;
