import React from 'react';
import styled, { ThemeProvider, keyframes } from 'styled-components';

import useDarkMode from '../../hooks/useDarkMode';
import Toggle from '../../assets/theme/Toggle';
import { lightTheme, darkTheme } from '../../assets/theme/theme';
import GlobalStyle from '../../assets/globalStyle';

const floatAnimation = keyframes`
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 16px);
  }
  100% {
    transform: translate(0px, 0px);
  }
`;

const App = styled.div`
  text-align: center;
`;

const AppHeader = styled.header`
  background-color: ${({ theme }) => theme.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  color: ${({ theme }) => theme.text};
  animation: ${floatAnimation} 4s infinite ease-in-out;
  transform: translate3d(0, 0, 0);
  height: 80vh;

  @font-face {
    font-family: CooperHewitt;
    src: url('../assets/webfonts/CooperHewitt-Bold.otf');
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.backgroundColor};
  white-space: nowrap;

  @media screen and (max-width: 800px) {
    font-size: 80%;
  }
`;

const Name = styled.h1`
  font-family: 'Spartan', sans-serif;
  margin-top: 15px;
  margin-bottom: 12px;
  font-size: 2em;
`;

const Description = styled.h2`
  font-size: 22px;
  margin-top: 5px;
  font-weight: 800;
  letter-spacing: 1px;

  @font-face {
    font-family: CooperHewitt;
    src: url('../assets/webfonts/CooperHewitt-Bold.otf');
  }

  @media screen and (max-width: 800px) {
    font-size: 80%;
  }
`;

const Est = styled.h3`
  font-size: 18px;
  margin-top: 0px;
  font-weight: 200;

  @font-face {
    font-family: CooperHewitt;
    src: url('../assets/webfonts/CooperHewitt-Bold.otf');
  }

  @media screen and (max-width: 800px) {
    font-size: 80%;
  }
`;

const Home: React.FC = () => {
  const [theme, toggleTheme, componentMounted] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  if (!componentMounted) {
    return <div />;
  }

  return (
    <ThemeProvider theme={themeMode}>
      <App>
        <GlobalStyle />
        <AppHeader>
          <Toggle theme={theme} toggleTheme={toggleTheme} />
          <Wrapper>
            <Name>GREG BANKS</Name>
            <Description>BUILDS STUFF ON INTERNET</Description>
            <Est>Established 2016</Est>
          </Wrapper>
        </AppHeader>
      </App>
    </ThemeProvider>
  );
};

export default Home;
